import React from "react";
import { ResponsiveLine } from "@nivo/line";
import * as Styled from "./Chart.styled";
import { Flex } from "..";

const Line = ({ data, color }) => {
  const chartWidth = "100%";
  if (!data) return null;
  return (
    <Flex align={"flex-end"} width="100%">
      <div style={{ width: `${chartWidth}` }}>
        <Styled.HeightProvider height="50px">
          <ResponsiveLine
            data={data}
            indexBy="utc"
            keys={["value"]}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            colors={[color]}
            enablePoints={false}
            enableArea={true}
            enableGridX={false}
            enableGridY={false}
            areaOpacity={1}
            useMesh={true}
            curve="natural"
          />
        </Styled.HeightProvider>
      </div>
    </Flex>
  );
};

export default Line;
