/*************************************************************
 * This component can be used as confirmation modal
 * for any API request either with a form submit or a
 * regular onClick.
 *
 * ACCEPTS either an `action` for onClick events or a
 * `formId` for form submissions.
 *
 * TAKES: title = heading of the modal
 *        message = subheading of the modal
 *************************************************************/

import React from "react";
import { GenericModal, Flex, Text, Button } from "components";

const ConfirmationModal = ({ title, message, action, close, formId }) => {
  return (
    <GenericModal zIndex={9999} width="494px" height="334px">
      <Flex column>
        <Flex width="100%" column gap="8px">
          <Text className="center" textAlign="center" kind="f4 b">
            {title}
          </Text>
          <Text kind="f3" className="center" textAlign="center">
            {message}
          </Text>
        </Flex>
        <Flex gap="16px" margin="16px 0">
          <Button
            onClick={close}
            kind="outline"
            size="16px"
            width="100%"
            padding="16px 32px"
            flexGrow="1"
            whiteSpace="nowrap"
          >
            No
          </Button>
          <Button
            {...(formId && !action && { type: "submit", form: formId })}
            {...(!formId &&
              action && {
                onClick: async (e) => {
                  await action(e);
                  close();
                },
              })}
            kind="primary"
            width="100%"
            padding="16px 32px"
            flexGrow="1"
            size="16px"
            whiteSpace="nowrap"
          >
            Yes
          </Button>
        </Flex>
      </Flex>
    </GenericModal>
  );
};

export default ConfirmationModal;
