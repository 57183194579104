import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import * as Styled from "./Chart.styled";
import { Flex, Text } from "..";

const BAR_WIDTH = 7;
const BAR_PADDING = 2;

const Tooltip = ({ label, value }) => {
  return (
    <Styled.Tooltip>
      <Styled.TooltipBackground>
        <Flex column width="100%">
          <Text kind="f9" color={"NEUTRAL_75"}>
            Date:
          </Text>
          <Text kind="f9" color={"NEUTRAL_75"}>
            {label}
          </Text>
          <Text padding="10px 0 0" kind="f8 b">
            {value}
          </Text>
        </Flex>
      </Styled.TooltipBackground>
    </Styled.Tooltip>
  );
};

const Bar = ({ data, type, color }) => {
  if (!data) return null;
  let chartWidth = data.length * (BAR_WIDTH + BAR_PADDING);

  let date;
  if (type === "flowers_count") {
    date = "order_date";
  } else {
    date = "utc";
  }

  const yAxisGroupings = [0];

  return (
    <Flex align={"flex-end"} width="100%">
      <div style={{ width: `${chartWidth}px` }}>
        <Styled.HeightProvider height="50px">
          <ResponsiveBar
            borderRadius={2}
            data={data}
            indexBy={date}
            keys={[`${type !== "flowers_count" ? type : "product_amount"}`]}
            padding={0.08}
            enableGridX={false}
            enableGridY={true}
            gridYValues={yAxisGroupings} //Bring back
            enableLabel={false}
            layout="vertical"
            colors={[color]}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            tooltip={({ data }) => {
              let value = 0;
              switch (type) {
                case "users":
                  value = data.users;
                  break;
                case "pageviews":
                  value = data.pageviews;
                  break;
                case "sessions":
                  value = data.sessions;
                  break;
                case "total_events":
                  value = data.total_events;
                  break;
                case "obit_count":
                  value = data.obit_count;
                  break;
                case "flowers_count":
                  value = 1;
                  break;
                default:
                  value = null;
              }
              return (
                <Tooltip
                  label={data.date || data.order_date || `${data.month}/2020`}
                  value={value}
                />
              );
            }}
            theme={{
              tooltip: {
                container: {
                  background: "transparent",
                  boxShadow: "0",
                },
              },
            }}
          />
        </Styled.HeightProvider>
      </div>
      <Styled.Remainder chartWidth={chartWidth} />
    </Flex>
  );
};

Bar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.array,
};

export default Bar;
