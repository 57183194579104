/*************************************************
 * 🗂 TABS FOR INDIVIDUAL REPORT NAV IN DASH 🗂 *
 *************************************************/

import React from "react";
import { Text, Flex } from "components";
import { TabWrapper, TabItem } from "./Tabs.styled";
import { useAnalytics } from "use-analytics";
import { colors } from "utils";
// ICONS
import { BsHouseDoorFill, BsFillPersonFill } from "react-icons/bs";
import { FaGlobeAmericas, FaPaperPlane, FaPeopleCarry } from "react-icons/fa";
import { MdHighlight } from "react-icons/md";
import { HiNewspaper } from "react-icons/hi";

const tabSize = "24px";
const tabItems = [
  // hiding overview tab for now as per Lee Warn 11-21-2023
  // {
  //   value: "overview",
  //   label: "Overview",
  //   forHomeType: ["parent", "chiefCorporation"],
  //   forPageType: "reports",
  //   icon: <BsHouseDoorFill style={{ fontSize: tabSize }} />,
  // },
  {
    value: "brandReach",
    label: "Brand Reach",
    forHomeType: ["parent", "child", "chiefCorporation"],
    forPageType: "reports",
    icon: <FaPaperPlane style={{ fontSize: tabSize }} />,
  },
  {
    value: "localAds",
    label: "Local Ads",
    forHomeType: ["parent"],
    forPageType: "reports",
    icon: <HiNewspaper style={{ fontSize: tabSize }} />,
  },
  {
    value: "obituaries",
    label: "Obituaries",
    forHomeType: ["parent", "child"],
    forPageType: "reports",
    icon: <BsFillPersonFill style={{ fontSize: tabSize }} />,
  },
  {
    value: "marketshare",
    label: "Obit Data",
    forHomeType: ["parent"],
    forPageType: "reports",
    icon: <FaGlobeAmericas style={{ fontSize: tabSize }} />,
  },
  {
    value: "leads",
    label: "Leads",
    forHomeType: ["parent"],
    forPageType: "reports",
    icon: <FaPeopleCarry style={{ fontSize: tabSize }} />,
  },
  {
    value: "localSpotlight",
    label: "Local Spotlight",
    forHomeType: ["parent", "child"],
    forPageType: "reports",
    icon: (
      <MdHighlight
        style={{
          fontSize: tabSize,
          transform: "rotateX(180deg)",
        }}
      />
    ),
  },
  {
    value: "funeralHomeDetails",
    label: "Details",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeUsers",
    label: "Users",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeFlowers",
    label: "Flowers",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeObituaries",
    label: "Obituaries",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeCompetitors",
    label: "Competitors",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeLSFlowers",
    label: "Local Spotlight Flowers",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "funeralHomeLSObits",
    label: "Local Spotlight Obituaries",
    forHomeType: ["parent", "child"],
    forPageType: "details",
    icon: null,
  },
  {
    value: "newSubscribers",
    label: "New Subscribers",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
  {
    value: "legacyCompetitors",
    label: "Competitors",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
  {
    value: "legacyFlower",
    label: "Flowers",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
  {
    value: "legacyObits",
    label: "Obituaries",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
  {
    value: "localSpotlight",
    label: "Local Spotlight",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
  {
    value: "googleAnalytics",
    label: "Google Analytics",
    forHomeType: ["parent", "child"],
    forPageType: "upload",
    icon: null,
  },
];

const Tabs = ({
  tab,
  setTab,
  fhlsEnabled,
  leadsEnabled,
  homeType,
  pageType,
}) => {
  const { track } = useAnalytics();
  return (
    <TabWrapper width="100%">
      {tabItems
        .filter(({ forHomeType }) => forHomeType.includes(homeType))
        .filter(({ forPageType }) => forPageType.includes(pageType))
        .filter(({ value }) =>
          value === "leads"
            ? leadsEnabled
            : value === "localSpotlight"
            ? fhlsEnabled
            : true
        )
        .map(({ value, label, icon }) => (
          <TabItem
            key={value}
            onClick={() => {
              track("clickedTab", {
                tab_value: value,
                tab_label: label,
              });
              setTab(value);
              localStorage.setItem("tab", JSON.stringify(value));
            }}
            {...(tab === value && { className: "active" })}
            justify="center"
            align="center"
          >
            {icon && (
              <Flex justify="center" align="center">
                <Flex
                  size="24px"
                  margin="0 10px 0 0"
                  color={tab === value ? colors.GOLD_100 : undefined}
                >
                  {icon}
                </Flex>
              </Flex>
            )}
            <Text kind="f6" color="NEUTRAL_50" className="text-field">
              {label}
            </Text>
          </TabItem>
        ))}
    </TabWrapper>
  );
};

export default Tabs;
